import React, { useState, useEffect, useCallback, useRef } from 'react';
import Video from './Video';
import { useUpdateEvent } from '../lib/EventEmitterEx';

interface ClientVideoProps
{
    client:any;
}

const divStyle:any={
    position:'absolute',
    left:'0px',
    top:'0px',
    width:'100%',
    height:'100%'
}

const size0={width:0,height:0};

export default function ClientVideo({client}:ClientVideoProps)
{

    const divRef=useRef<HTMLDivElement>(null);
    const div=divRef.current;
    const [divSize,setDivSize]=useState(size0);
    const [videoSize,setVideoSize]=useState(size0);
    useEffect(()=>{
        if(!div){
            return;
        }
        setDivSize({width:div.clientWidth,height:div.clientHeight});
        if(!(window as any).ResizeObserver){
            return;
        }
        const obv=new (window as any).ResizeObserver(()=>{
            setDivSize({width:div.clientWidth,height:div.clientHeight});
        });
        obv.observe(div);

        return ()=>{
            obv.unobserve(div);
        }
    },[div]);

    const [stream,setStream]=useState(null);
    useEffect(()=>{
        if(client){
            client.onStreamChange((s:any)=>setStream(s));

        }
    },[client]);

    useUpdateEvent(client,'rotation');

    const onSize=useCallback((width:number,height:number)=>{
        setVideoSize({
            width:width,
            height:height
        })
    },[]);

    if(!client){
        return null;
    }

    const rotation=client.getRotation();

    const ro=rotation===0 || rotation===180;
    const vw=ro?videoSize.width:videoSize.height;
    const vh=ro?videoSize.height:videoSize.width;
    const vAr=vh===0?1:vw/vh;

    const cAr=divSize.height===0?1:divSize.width/divSize.height;
    let cw,ch;

    const wider=vAr>cAr;
    if(wider){
        cw=divSize.width;
        ch=vw===0?cw:divSize.width/vw*vh;
    }else{
        ch=divSize.height;
        cw=vh===0?ch:divSize.height/vh*vw;
    }


    const vs:any={
        position:'absolute',
        left:vw===0?divSize.width/2:((divSize.width-(ro?cw:ch))/2)+'px',
        top:vh===0?divSize.height/2:((divSize.height-(ro?ch:cw))/2)+'px',
        width:vw===0?0:(ro?cw:ch)+'px',
        height:vh===0?0:(ro?ch:cw)+'px',
        objectFit:'fill',
        transformOrigin:'center',
        transform:'rotate('+rotation+'deg)'
    }


    return (
        <div style={divStyle} ref={divRef}>
            <Video
                style={vs}
                onSize={onSize}
                className={(vh&&vw?'ready':'')}
                src={stream}
                autoPlay={true}
                controls={false} />
        </div>
    )
}