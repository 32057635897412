import EventEmitterEx from './EventEmitterEx';
import RoomManager from './RoomManager';
import { RoomJoinInfo } from './types';
import { Nav, defaultNav } from '../CommonJs/Nav';
import ObjStore from '../CommonJs/ObjStore';
import WebRtcInfoBrowser from './WebRtcInfBrowser';

export default class AeroApp extends EventEmitterEx
{

    readonly roomManager:RoomManager;

    readonly nav:Nav;

    readonly store:ObjStore;

    private _joinInfo:RoomJoinInfo|null=null;
    get joinInfo(): RoomJoinInfo|null {
        return this._joinInfo;
    }
    set joinInfo(value:RoomJoinInfo|null){
        if(value===this._joinInfo){
            return;
        }
        this._joinInfo=value;
        this.store.save('joinInfo',this._joinInfo);
        this.emit('joinInfo',this._joinInfo);
    }

    constructor()
    {
        super();
        this.store=new ObjStore('aero-app');
        let rtc:WebRtcInfoBrowser|null=null;
        try{
            rtc=new WebRtcInfoBrowser();
        }catch{}
        this.roomManager=new RoomManager(rtc);
        this.nav=defaultNav;
    }

    async initAsync():Promise<boolean>
    {

        this.joinInfo=this.store.load<RoomJoinInfo>('joinInfo');


        return true;
    }


    


}