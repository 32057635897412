const LogLevel={
    none:0,
    info:1,
    warn:2,
    error:4,
    all:7
}
let _level=LogLevel.all;
function formatMessage(message,ex){
    if(!ex && typeof(message)==='object'){
        return message;
    }
    if(message && ex){
        return message+': '+ex.message;
    }else if(message){
        return message;
    }else if(ex){
        return ex.message;
    }else{
        return '(empty)';
    }
}
export default {
    setLevel:(level)=>{
        _level=level&LogLevel.all;
    },
    getLevel:()=>_level,

    info:(message,ex)=>{
        const msg=formatMessage(message,ex);
        if(_level&LogLevel.info){
            console.log(msg);
        }
        return msg;
    },
    warn:(message,ex)=>{
        const msg=formatMessage(message,ex);
        if(_level&LogLevel.warn){
            console.warn(msg);
        }
        return msg;
    },
    error:(message,ex)=>{
        const msg=formatMessage(message,ex);
        if(_level&LogLevel.error){
            console.error(msg);
        }
        return msg;
    }
}
export { LogLevel }