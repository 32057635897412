import React, { useCallback, useState, useEffect } from 'react';
import RoomManager from '../lib/RoomManager';
import { useProperty } from '../lib/EventEmitterEx';
import './RoomBg.scss';
import util from '../lib/util';
import ImageAttribution from './ImageAttribution';

let nextKey=0;

interface RoomBgProps
{
    roomManager:RoomManager;
}

export default function RoomBg({roomManager}:RoomBgProps)
{

    const room=useProperty(roomManager,'room',m=>m.room);
    const bgSrc=(room && room.Data.pagebg) as string|null;
    let bgImageAtt=bgSrc?<ImageAttribution roomData={room&&room.Data}/>:null;

    const [imgStack,setImgStack]=useState<{url:string|null,key:number}[]>([]);

    useEffect(()=>{
        setImgStack(s=>{
            s=[...s,{url:bgSrc,key:nextKey++}];
            while(s.length>10){
                s.shift();
            }
            return s;
        });
    },[bgSrc]);

    return (
        <>
            <div className="room-bg">
                {imgStack.map((src,index)=>(
                    src.url?<TransImg key={src.key} src={src.url} top={index===imgStack.length-1}/>:null
                ))}
            </div>
            {bgImageAtt}
        </>
    )

    
}

function TransImg({src,top}:{src:string,top:boolean})
{
    const [loaded,setLoaded]=useState<boolean>(false);
    const onLoad=useCallback(()=>{
        setLoaded(true);
    },[]);
    const onLoadStart=useCallback(()=>{
        setLoaded(false);
    },[]);


    const [ready,setReady]=useState<boolean>(false);
    useEffect(()=>{
        const iv=setTimeout(()=>setReady(true),100);
        return ()=>clearTimeout(iv);
    },[]);


    const [hidden,setHidden]=useState<boolean>(false);
    const [willHide,setWillHide]=useState<boolean>(false);
    useEffect(()=>{

        if(top){
            return;
        }

        let active=true;

        async function work()
        {
            await util.delayAsync(7000);
            if(!active){
                return;
            }
            setWillHide(true);
            await util.delayAsync(3000);
            if(!active){
                return;
            }
            setHidden(true);
        }

        work();

        return ()=>{
            active=false;
        }
        
    },[top]);
    if(hidden){
        return null;
    }

    return <img
        className={((ready && loaded && !willHide)?'loaded':'not-loaded')}
        alt="background"
        src={src}
        onLoad={onLoad}
        onLoadStart={onLoadStart}/>
}