import React, { useEffect } from 'react';
import util from '../lib/util';
import ClientVideo from './ClientVideo';
import RoomBg from './RoomBg';
import BgPicker from './BgPicker';
import { useUpdateEvent, useProperty } from '../lib/EventEmitterEx';
import { layoutsTemplates, fullScreenLayout } from '../lib/Layouts';
import { MdClose } from 'react-icons/md';
import './Room.scss';
import { useApp, useBodyClass } from '../lib/hooks';
import { RoomJoinInfo } from '../lib/types';

interface RoomProps
{
    name:string|null;
    showPicker?:boolean;
}

const defaultJoinInfo:RoomJoinInfo={
    clientName:'user'+new Date().getTime(),
    videoDevice:'*None',
    relay:false
}

export default function Room({
    name:roomName,
    showPicker=false
}:RoomProps){

    useBodyClass('pause-bg-animation');

    const app=useApp();
    const mgr=app.roomManager;
    useUpdateEvent(mgr,'data');
    const room=useProperty(mgr,'room',m=>m.room);
    const joinInfo=useProperty(app,'joinInfo',a=>a.joinInfo||defaultJoinInfo);

    useEffect(()=>{

        if(!joinInfo || !roomName || !joinInfo.clientName || !joinInfo.videoDevice){
            return;
        }

        mgr.joinRoomAsync(joinInfo.clientName,roomName,joinInfo.relay,joinInfo.videoDevice);

        return ()=>{
            mgr.close();
        }

    },[mgr,joinInfo,roomName]);

    

    const hideAll=util.aryAny(mgr._clients,(c:any)=>{return c.Data&&c.Data.hideAll});
    const clients=room?room.Clients.filter((client:any)=>client&&client.IsContentProvider):[];
    const fullClient=room?util.firstOrDefault(room.Clients,null,(c:any)=>c.Id===room.Data.fullscreen):null;
    const layouts=layoutsTemplates[clients.length];
    const empty=clients.length===0;

    return (<div className={"room d-flex flex-fill flex-column"+(empty?' room-empty':'')}>

        <RoomBg roomManager={mgr} />

        <ul className="room-layout">
            {clients.map((client:any,i:number)=>{

                const isFull=client===fullClient;
                const layout=isFull?fullScreenLayout:layouts[i];

                return (
                    <li key={client.Id} style={layout} className={hideAll?'hide':(fullClient?(fullClient===client?'fullscreen':'hide'):'')}>
                        <span>{client.Name}</span>
                        <ClientVideo client={mgr.getClientById(client.Id)} />
                    </li>
                )
            })}
        </ul>
        

        <div className="room-layout-empty">
            <div>Waiting for Devices to Join</div>
        </div>
        
        <button className="disconnect-btn" onClick={()=>app.nav.push('/')}><MdClose/></button>

        {showPicker&&<BgPicker roomManager={mgr} />}
        
    </div>)
}