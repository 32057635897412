import WebRtcInf from './WebRtcInf';
require('webrtc-adapter');

export default class WebRtcInfBrowser implements WebRtcInf
{
    getDisplayMediaSupported:boolean;

    constructor()
    {
        this.getDisplayMediaSupported=(navigator.mediaDevices as any).getDisplayMedia?true:false;
    }

    async getDisplayMedia(config:any):Promise<MediaStream|null>
    {
        if(!this.getDisplayMediaSupported){
            return null;
        }
        return await (navigator.mediaDevices as any).getDisplayMedia(config);
    }
    getUserMedia(constraints?: MediaStreamConstraints): Promise<MediaStream>
    {
        return navigator.mediaDevices.getUserMedia(constraints as MediaStreamConstraints);
    }

    enumerateDevices(): Promise<MediaDeviceInfo[]>{
        return navigator.mediaDevices.enumerateDevices();
    }


    stopMediaStream(stream:MediaStream):void
    {
        if(!stream){
            return;
        }

        if(stream.getTracks){
            const tracks=stream.getTracks();
            for(let t of tracks){
                if(t){
                    try{
                        t.stop();
                    }catch(ex){
                        console.log('WebRtcInfBrowser.stopMediaTrack error when stopping track',ex)
                    }
                }
            }
        }

        if((stream as any).stop){
            try{
                (stream as any).stop();
            }catch(ex){
                console.log('WebRtcInfBrowser.stopMediaTrack error when stopping stream',ex)
            }
        }
    }
}