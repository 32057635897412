export interface VideoLayout{
    left:string;
    top:string;
    width:string;
    height:string;
}

export const layoutsTemplates:VideoLayout[][]=[
    [],

    [//fullscreen
        {
            left:'10%',
            top:'10%',
            width:'80%',
            height:'80%'
        }
    ],

    [//side by side
        {
            left:'0%',
            top:'0%',
            width:'50%',
            height:'100%'
        },
        {
            left:'50%',
            top:'0%',
            width:'50%',
            height:'100%'
        }
    ],

    [//Triple
        {
            left:'0%',
            top:'0%',
            width:'50%',
            height:'100%'
        },
        {
            left:'50%',
            top:'0%',
            width:'50%',
            height:'50%'
        },
        {
            left:'50%',
            top:'50%',
            width:'50%',
            height:'50%'
        },
    ],

    [//Quad
        {
            left:'0%',
            top:'0%',
            width:'50%',
            height:'50%'
        },
        {
            left:'50%',
            top:'0%',
            width:'50%',
            height:'50%'
        },
        {
            left:'0%',
            top:'50%',
            width:'50%',
            height:'50%'
        },
        {
            left:'50%',
            top:'50%',
            width:'50%',
            height:'50%'
        },
    ],
]

export const fullScreenLayout:VideoLayout={
    left:'0%',
    top:'0%',
    width:'100%',
    height:'100%'
}