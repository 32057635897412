import axios from 'axios';
import log from './log';

const _log=false;

export default class Http{

    
    _baseUrl;

    constructor(baseUrl)
    {
        this._baseUrl=baseUrl;
    }

    getAsync(path){
        return this._doAsync('GET',path,()=>axios.get(this._baseUrl+path));
    }

    postAsync(path,data){
        return this._doAsync('POST',path,()=>axios.post(this._baseUrl+path,data));
    }

    async _doAsync(name,path,work){
        
        let success=false;
        try{
            const result=await work();
            success=true;
            if(_log){
                log.info(name+': '+path);
                log.info(result.data);
            }
            return result.data;
        }finally{
            if(!success){
                if(_log){
                    log.error(name+': '+path);
                }
            }
        }
    }

}