import React, { useState, useCallback } from 'react';
import RoomManager from '../lib/RoomManager';
import { MdImage } from 'react-icons/md';
import './BgPicker.scss';

interface BgPickerProps
{
    roomManager:RoomManager;
}

export default function BgPicker({roomManager}:BgPickerProps)
{
    const [showBg,setShowBg]=useState(false);
    const [bgImg,setBgImg]=useState<string>('');
    const submitBg=useCallback((e:any)=>{
        e.preventDefault();

        roomManager.setData('pagebg',bgImg);

        setShowBg(false);
    },[roomManager,bgImg]);

    return (

        <>
            {!showBg&&<button className="bg-btn" onClick={()=>setShowBg(true)}><MdImage/></button>}

            {showBg&&<div className="bg-form">
                <form onSubmit={submitBg}>
                    <div className="d-flex flex-row">
                        <input className="form-input" value={bgImg}  onChange={(e)=>setBgImg(e.target.value)} />
                        <button className="btn btn-primary ml-3" type="submit">Update</button>
                    </div>
                </form>
            </div>}
        </>

    )
}