import React, { useMemo } from 'react';
import Page from './components/Page';
import Room from './components/Room';
import Join from './components/Join';
import './App.scss';
import { NavRoute } from './CommonJs/Nav';
import {AeroAppContext} from "./lib/hooks";
import AeroApp from './lib/AeroApp';
import { useAsync } from './CommonJs/utilTs';

const App: React.FC = () => {

    const app=useMemo(()=>{
        const app=new AeroApp();
        console.log("AeroApp",app);
        return app;
    },[]);

    const inited=useAsync(false,()=>app.initAsync(),[app]);

    if(!inited){
        return null;
    }

    return (
        <AeroAppContext.Provider value={app}>

            <NavRoute path="/">
                <Page><Join /></Page>
            </NavRoute>

            <NavRoute match={/\/room\/(.*)/} render={(m) => {

                return <Page><Room name={m.success?m.matches![1]:null} /></Page>
            }} />


        </AeroAppContext.Provider>
    );
}

export default App;
