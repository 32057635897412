import React, { useContext, useEffect } from "react"
import AeroApp from "./AeroApp"

export const AeroAppContext=React.createContext<AeroApp|null>(null);

export function useApp():AeroApp
{
    return useContext(AeroAppContext) as AeroApp;
}

export function useBodyClass(className:string)
{
    useEffect(()=>{
        if(!className){
            return;
        }
        document.body.classList.add(className);
        return ()=>{
            document.body.classList.remove(className);
        }
    },[className]);
}