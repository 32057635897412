import Http from "./http";
import config from '../config';
import util from "./util";
import log from './log';
import MediaDevice from "./MediaDevice";
import EventEmitterEx from "./EventEmitterEx";
import WebRtcInf from './WebRtcInf';// eslint-disable-line

export const screenCaptureId='*ScreenCapture';
export const noneId='*None';

export function formatRoomName(name){
    if(!name){
        return null;
    }
    return name.trim().toLowerCase().replace(/\s{2,}/," ");
}

const RoomEventType={
    None : 0,
    UpdateRoom : 1,
    RequestOffer : 2,
    ReturnOffer : 3,
    ReturnAnswer : 4,
    IceCandidates : 5,
    RequestIceCandidates : 6,
    Rotation : 7,
    SetData : 8,
    Ping : 9,
    Pong: 10
}

const SpecialAddresses={
    None: 0,
    Host: 1,
    All : 2,
    AllButSelf: 3
}

export const ConnectionState={
    disconnected:0,
    connecting:1,
    connected:2
}

class RoomManager extends EventEmitterEx{

    _http;
    http;
    _localClient;

    room=null;
    _onRoomChangeEvt;
    onRoomChange;


    _clients=[];
    _clientUpdateId=0;
    _ws;

    mediaDevices=[];
    _onMediaDevicesChangeEvt;
    onMediaDevicesChange;

    _preferredVideoDeviceId=null;
    _forceRelay=false;

    _peerConfig;

    isContentConsumer=true;
    closeOnTargetClientUnset=false;
    targetDeviceUUID=null;
    targetClient=null;

    connectionState=ConnectionState.disconnected;

    /** @type {WebRtcInf|null} */
    webRtcInf;

    constructor(webRtcInf)
    {
        super()

        this.webRtcInf=webRtcInf;

        this.http=this._http=new Http(config.apiBaseUrl);

        this._onRoomChangeEvt=util.createEvent();
        this.onRoomChange=this._onRoomChangeEvt.add;

        this._onMediaDevicesChangeEvt=util.createEvent();
        this.onMediaDevicesChange=this._onMediaDevicesChangeEvt.add;

        // if( window &&
        //     window.navigator &&
        //     window.navigator.mediaDevices &&
        //     window.navigator.mediaDevices.enumerateDevices)
        // {
        //     this.navigatorMediaDevices=window.navigator.mediaDevices;
        // }else{
        //     //this.navigatorMediaDevices=mediaDevices;
        // }

        this.updateMediaDevices();
    }

    setDeviceVideoDevice(deviceId)
    {
        this._preferredVideoDeviceId=deviceId;
    }

    async updateMediaDevices()
    {
        const list=this.webRtcInf?(await this.webRtcInf.enumerateDevices()):[];
        const devices=[];
        for (let i = 0; i < list.length; i++) {
            const device = list[i];
            if(device.kind==='videoinput'){
                devices.push(new MediaDevice(device));
            }
            
        }
        if(this.webRtcInf && this.webRtcInf.getDisplayMediaSupported){
            devices.push(new MediaDevice(null,screenCaptureId,'Share Screen'))
        }
        devices.unshift(new MediaDevice(null,noneId,'None'));
        this.mediaDevices=devices;
        this._onMediaDevicesChangeEvt(this.mediaDevices);
    }

    async joinRoomAsync(name,roomName,relay,preferredVideoDeviceId){

        roomName=formatRoomName(roomName);

        if(preferredVideoDeviceId){
            this._preferredVideoDeviceId=preferredVideoDeviceId;
        }

        if(relay!==undefined){
            this._forceRelay=relay;
        }

        this.connectionState=ConnectionState.connecting;
        this.emit('connectionState',this.connectionState);

        await this.updatePeerConfigAsync();

        const localClient=await this._http.postAsync('Room/CreateClient',{
            name,
            isContentConsumer:this.isContentConsumer,
            isContentProvider:(preferredVideoDeviceId && preferredVideoDeviceId!==noneId)?true:false
        });

        this._localClient=localClient;

        let wsMessage=false;
        let rs=null;
        let rj=null;
        const promise=new Promise((_rs,_rj)=>{
            rs=_rs;
            rj=_rj;
        });

        const ws=new WebSocket(`${config.webSocketBaseUrl}Room/${encodeURIComponent(roomName)}/Join/${localClient.Id}`);
        const heartBeat=setInterval(()=>{
            if(wsMessage){
                this.sendMessage(SpecialAddresses.Host,RoomEventType.Ping);
            }
        },40*1000);
        ws.onmessage=msg=>{

            if(!wsMessage){
                wsMessage=true;
                rs();
            }

            try{
                const evt=JSON.parse(msg.data);
                this._handleEvent(evt);
            }catch(ex){
                console.error('On message error',ex,msg);
            }
        };
        ws.onclose=(e)=>{
            if(!wsMessage){
                wsMessage=true;
                rj(e);
            }
            clearInterval(heartBeat);
            this._close();
        }
        this._ws=ws;

        await promise;

        this.connectionState=ConnectionState.connected;
        this.emit('connectionState',this.connectionState);
    }

    close()
    {
        const ws=this._ws;
        if(ws!=null){
            ws.close();
        }
    }

    _close(){
        if(!this.connectionState===ConnectionState.disconnected){
            return;
        }

        this.closeStreams();

        this.connectionState=ConnectionState.disconnected;
        this.emit('connectionState',this.connectionState);

        this._setRoom(null);
    }

    closeStreams()
    {
        for(let client of this._clients){
            client.dispose();
        }
        for(let client of this._localStreams){
            client.dispose();
        }
        
    }

    async updatePeerConfigAsync()
    {
        if(!this._peerConfig){
            try{
                this._peerConfig=await this._http.getAsync('Ice');
            }catch(ex){
                console.log('Failed to get ice servers',ex);
                this._peerConfig={iceServers:[]}
            }
        }
    }

    _handleEvent(evt){

        console.log('recv:'+evt.SenderId,evt);

        switch(evt.Type){

            case RoomEventType.UpdateRoom:
                this._setRoom(evt.Value);
                break;

            case RoomEventType.RequestOffer:
                this._createOfferFor(evt.SenderId);
                break;

            case RoomEventType.ReturnOffer:
            {
                const client=this.getClientById(evt.SenderId);
                if(client){
                    client.acceptOffer(evt.Value);
                }
                break;
            }

            case RoomEventType.ReturnAnswer:
            {
                const localStream=this.getLocalStreamById(evt.SenderId);
                if(localStream){
                    localStream.acceptAnswer(evt.Value);
                }
                break;
            }

            case RoomEventType.IceCandidates:
                this.getIceClientsById(evt.SenderId).forEach(c=>c.acceptIceCandidates(evt.Value));
                break;

            case RoomEventType.RequestIceCandidates:
                this.getIceClientsById(evt.SenderId).forEach(c=>{
                    c.sendIceCandidates(evt.SenderId);
                });
                break;

            case RoomEventType.Rotation:
                    this.getIceClientsById(evt.SenderId).forEach(c=>{
                        c.setRotation(evt.Value);
                    });
                break;

            case RoomEventType.SetData:
                this._setRoomData(evt.Value);
                break;

            case RoomEventType.Ping:
                this.sendMessage(evt.SenderId,RoomEventType.Pong);
                break;

            case RoomEventType.Pong:
                //do nothing
                break;


            default:
                log.warn('Unknown event type: '+evt.Type);
                break;
        }
    }

    _setRoomData(data){
        const room=this.room;
        if(!room){
            return;
        }
        if(data.client){
            const client=this.getClientById(data.client);
            if(client){
                if(!client.Data){
                    client.Data={};
                }
                client.Data[data.name]=data.value;
            }
        }else{
            if(!room.Data){
                room.Data={};
            }
            room.Data[data.name]=data.value;
        }
        this.emit('data',room.Data);
    }

    _setRoom(room){
        this.room=room;
        this._updateClients();
        this._onRoomChangeEvt(room);
        this.emit('room',room);
    }

    _updateClients(){
        this._clientUpdateId++;
        const room=this.room;
        const clients=this._clients;
        const updateId=this._clientUpdateId;
        if(room){
            for(let i=0;i<room.Clients.length;i++){
                const clientState=room.Clients[i];
                let client=util.first(clients,c=>c.id===clientState.Id);
                if(!client){
                    client=new Client(
                        this,clientState,
                        (this._localClient && clientState.Id===this._localClient.Id)?this._localClient:null,
                        this._peerConfig);
                    clients.push(client);

                    //todo - move request to a better location
                    if(client.localClient){
                        client.openLocalStream();
                    }else{
                        if(clientState.IsContentProvider && this.isContentConsumer){
                            client.requestRemoteStream();
                        }
                    }
                }
                client.updateId=updateId;
            }
        }
        for(let i=0;i<clients.length;i++){
            const client=clients[i];
            if(client.updateId!==updateId){
                clients.splice(i,1);
                i--;
                client.dispose();
            }
        }

        const targetClient=this.targetDeviceUUID?util.firstOrDefault(clients,null,c=>c.state.DeviceUUID===this.targetDeviceUUID):null;
        this._setTargetClient(targetClient);
        this.emit('clients');
    }

    _setTargetClient(client){
        if(this.targetClient===client){
            return;
        }
        this.targetClient=client;
        this.emit('targetClient',client);

        if(!this.targetClient){
            this.close();
        }
    }

    _localStreams=[];

    async _createOfferFor(senderId){
        const stream=new LocalStream(this,senderId,this._peerConfig);
        this._localStreams.push(stream);
        const offer=await stream.createOffer();
        this.sendMessage(senderId,RoomEventType.ReturnOffer,offer);
    }

    setData(name,value){
        this.sendMessage(SpecialAddresses.Host,RoomEventType.SetData,{name,value});
    }

    sendMessage(destId,type,value){
        if(type===undefined){
            throw new Error('undefined message type');
        }
        const evt={
            Type:type,
            SenderId:this._localClient.Id,
            Value:value||null
        }
        const message=destId+':'+JSON.stringify(evt);
        console.log('send:'+destId,evt);
        this._ws.send(message);
    }

    getClientById(id){
        return util.first(this._clients,c=>c.id===id);
    }

    getLocalStreamById(id){
        return util.first(this._localStreams,s=>s.id===id);
    }

    getIceClientsById(id){
        const list=[];
        let c=this.getClientById(id);
        if(c){
            list.push(c);
        }
        c=this.getLocalStreamById(id);
        if(c){
            list.push(c);
        }
        return list;
    }

    getLocalStreamConstraints()
    {
        const c={};

        if(this._preferredVideoDeviceId){
            c.video={deviceId:{exact:this._preferredVideoDeviceId}};
        }else{
            c.video=true;
        }
        return c;
    }

    _localMediaStream;
    async getLocalMediaStream()
    {
        if(!this._localMediaStream){
            this._localMediaStream=this._getLocalMediaStream();
        }
        return await this._localMediaStream;
    }
    async _getLocalMediaStream()
    {
        let stream;
        if(this._preferredVideoDeviceId===noneId){
            stream = null;
        }else if(this._preferredVideoDeviceId===screenCaptureId){
            if(this.webRtcInf  && this.webRtcInf.getDisplayMediaSupported){
                stream = await this.webRtcInf.getDisplayMedia({video:true});
            }else{
                stream = null;
            }
        }else{
            if(this.webRtcInf){
                stream=await this.webRtcInf.getUserMedia(this.getLocalStreamConstraints());
            }else{
                stream=null;
            }
        }
        return stream;
    }

}

class IceClient extends EventEmitterEx{

    id;

    /** @type {RoomManager} */
    mgr;

    /** @type {RTCPeerConnection} */
    pc;

    /** @type {MediaStream} */
    stream;

    iceCandidates=[];

    onStreamChange;
    _onStreamChangeEvt;

    state;

    constructor(mgr,id,peerConfig)
    {
        super();
        this.mgr=mgr;
        this._onStreamChangeEvt=util.createEvent();
        this.onStreamChange=this._onStreamChangeEvt.add;
        this.id=id;
        this.pc=new RTCPeerConnection(peerConfig);
        this.pc.addEventListener('icecandidate', e => this.onIceCandidate(e));
    }

    dispose()
    {
        const pc=this.pc;
        this.pc=null;
        if(pc){
            try{
               pc.close();
            }catch(ex){
                console.log('IceClient.pc.close error',ex);
            }
        }

        const stream=this.stream;
        this.stream=null;
        if(stream && this.mgr.webRtcInf){
            this.mgr.webRtcInf.stopMediaStream(stream);
        }
    }

    getLocalState()
    {
        if(!this.state){
            this.state={};
        }

        if(!this.state.LocalState){
            this.state.LocalState={};
        }
        return this.state.LocalState;
    }

    getRotation()
    {
        const ls=this.getLocalState();
        return ls.Rotation||0;
    }

    setRotation(rotation)
    {
        const ls=this.getLocalState();
        if(rotation === ls.Rotation){
            return;
        }

        ls.Rotation=rotation;
        this.emit('rotation',rotation);
    }

    onIceCandidate(e){
        const c=e.candidate;
        if(this._addIceCandidate(c)){

            this.mgr.sendMessage(SpecialAddresses.AllButSelf,RoomEventType.IceCandidates,[c]);
        }
    }

    acceptIceCandidates(candidates)
    {
        candidates.forEach(c=>{
            try{
                if(this.mgr._forceRelay && c.candidate.indexOf('relay')<0){
                    return;
                }
                this.pc.addIceCandidate(c).catch(err=>{});
            }catch(ex){
                
            }
        });
    }

    _addIceCandidate(candidate)
    {
        if(!candidate || util.aryAny(this.iceCandidates,c=>util.areEqualShallow(c,candidate))){
            return false;
        }
        this.iceCandidates.push(candidate);
        return true;
    }

    sendIceCandidates(destId)
    {
        if(this.iceCandidates.length>0){
            this.mgr.sendMessage(destId,RoomEventType.IceCandidates,this.iceCandidates);
        }
    }

    setStream(stream,type)
    {
        if(stream===this.stream){
            return;
        }
        this.stream=stream;
        this._onStreamChangeEvt(stream);
        console.log(type+' stream',stream);
    }
}

class Client extends IceClient{

    updateId=0;

    localClient;

    constructor(mgr,clientState,localClient,peerConfig)
    {
        super(mgr,clientState.Id,peerConfig);
        this.localClient=localClient;
        this.state=clientState;
        this.Data=clientState.Data;
        if(localClient){
            this.pc=null;
        }else{
            this.pc.addEventListener('track', this.onTrack);
            this.pc.addEventListener('trackAdded',this.onTrack);
            this.pc.ontrack=this.onTrack;
            //this.pc.onaddstream=this.onAddStream;
        }
    }

    onAddStream=(e)=>{
        const streams=this.pc.getRemoteStreams();
        if(streams && streams.length){
            this.setStream(streams[0],'Remote');
        }
    }

    requestRemoteStream(){
        this.mgr.sendMessage(this.id,RoomEventType.RequestOffer);
    }

    async openLocalStream(){
        const stream=await this.mgr.getLocalMediaStream();
        this.setStream(stream,'Local');
    }

    async acceptOffer(offer)
    {
        this.pc.setRemoteDescription(offer);
        const answer=await this.pc.createAnswer();
        this.pc.setLocalDescription(answer);
        this.mgr.sendMessage(this.id,RoomEventType.ReturnAnswer,answer);
        this.mgr.sendMessage(this.id,RoomEventType.RequestIceCandidates);
    }

    onTrack=(e)=>
    {
        const stream=e.streams[0];
        if(!stream || this.stream){
            return;
        }
        this.setStream(stream,'Remote');

    }
}

class LocalStream extends IceClient{

    async createOffer(){

        const pc=this.pc;
        const stream=await this.mgr.getLocalMediaStream();
        if(!stream){
            return null;
        }
        this.setStream(stream,'Source');
        //pc.addEventListener('iceconnectionstatechange', e => this.onIceStateChange(pc,client, e));

        // if(pc.addStream){
        //     pc.addStream(stream);
        // }

        if(pc.addTrack){
            stream.getTracks().forEach(track => pc.addTrack(track, stream));
        }

        const offerOptions = {
            offerToReceiveAudio: 1,
            offerToReceiveVideo: 1
        };
        const offer = await pc.createOffer(offerOptions);
        pc.setLocalDescription(offer);
        return offer;
    }

    async acceptAnswer(answer){
        const pc=this.pc;

        pc.setRemoteDescription(answer);

        this.mgr.sendMessage(this.id,RoomEventType.RequestIceCandidates);
    }

}

export default RoomManager;