class MediaDevice{

    id:string;

    name:string;
    
    deviceInfo:MediaDeviceInfo|null;
    
    constructor(deviceInfo:MediaDeviceInfo, altId:string, altName:string)
    {
        if(deviceInfo){
            this.deviceInfo=deviceInfo;
            this.id=deviceInfo.deviceId;
            this.name=deviceInfo.label;
        }else{
            this.deviceInfo=null;
            this.id=altId;
            this.name=altName;
        }
    }
}

export default MediaDevice;