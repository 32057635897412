import React, { useRef, useEffect } from 'react';

interface VideoProps
{
    src?:any;
    onSize?:(width:number,height:number)=>void;
    [other:string]:any;
}

const Video=({src,onSize,...props}:VideoProps)=>{


    const vidRef=useRef<HTMLVideoElement>(null);
    const vid=vidRef.current;
    

    useEffect(()=>{
        if(!vid){
            return;
        }
        const vAny=vid as any;
        if(!src){
            vAny.src=null;
            vAny.srcObject=null;
            return;
        }
        
        if ("srcObject" in vAny) {
            if(vAny.srcObject!==src){
                vAny.srcObject=src;
            }
        }else{
            vAny.src=window.URL.createObjectURL(src);
        }

        const onMeta=function (e:any) {
            if(onSize){
                onSize(vid.videoWidth,vid.videoHeight);
            }
        };

        vAny.addEventListener( "loadedmetadata", onMeta);

        if(onSize){
            onSize(vid.videoWidth,vid.videoHeight);
        }

        return ()=>{
            vAny.removeEventListener( "loadedmetadata", onMeta);
        }
    },[vid,onSize,src])


    return <video  {...props} ref={vidRef} />

}

export default Video;