import React, { useState, useEffect, useCallback } from 'react';
import { useApp } from '../lib/hooks';
import { noneId, formatRoomName } from '../lib/RoomManager';
import './Join.scss';

export default function Join()
{

    const app=useApp();
    const mgr=app.roomManager;

    const [clientName,setClientName]=useState<string>('');
    const [roomName,setRoomName]=useState<string>('');
    const [relay,setRelay]=useState(false);
    const [videoDevice,setVideoDevice]=useState(noneId);
    const [mediaDevices,setMediaDevices]=useState(mgr.mediaDevices);
    useEffect(()=>{
        setMediaDevices(mgr.mediaDevices);
        return mgr.onMediaDevicesChange((v:any)=>{
            setMediaDevices(v);
        });
    },[mgr]);

    const joinRoom=useCallback((e)=>{
        e.preventDefault();
        app.joinInfo={
            clientName,
            relay,
            videoDevice
        };
        app.nav.push('/room',formatRoomName(roomName));
    },[clientName,roomName,relay,videoDevice,app]);

    return (
        <div className="room-join-container">
            <div className="container">
                {/* <h2>{status}</h2> */}
                <form onSubmit={joinRoom}>
                    <div className="form-row">
                        
                        <div className="col-12">
                            <input placeholder="Your Name" className="form-control" value={clientName} onChange={e=>setClientName(e.target.value)} />
                        </div>
                        
                        <div className="col-12 mt-4">
                            <input placeholder="Room Name" className="form-control" value={roomName} onChange={e=>setRoomName(e.target.value)} />
                        </div>
                        
                    </div>

                    <div className="form-row mt-4">

                        <div className="col">
                            <select value={videoDevice} onChange={e=>setVideoDevice(e.target.value)} className="form-control">
                                {mediaDevices.map(m=>(
                                    <option key={m.id} value={m.id}>{m.name}</option>
                                ))}
                            </select>
                        </div>

                    </div>

                    <div className="form-row mt-4">
                        <div className="col">
                            <button className="btn btn-primary w-100" disabled={!clientName || !roomName}>Join</button>
                        </div>
                    </div>

                    <div className="form-row mt-5">
                        

                    <div className="col">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="relayInput" checked={relay} onChange={e=>setRelay(e.target.checked)}/>
                                <label className="custom-control-label" htmlFor="relayInput">Force Relay</label>
                            </div>
                        </div>
                        
                    </div>
                </form>
            </div>
        </div>
    )
}