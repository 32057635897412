import React from 'react';

interface ImageAttributionProps
{
    roomData?:any;
    pagebgUnsplash?:any;
}

export default function ImageAttribution({
    pagebgUnsplash,
    roomData,
}:ImageAttributionProps){

    const us=pagebgUnsplash||(roomData&&roomData.pagebgUnsplash);
    if(us && us.user){
        return (
            <div className="image-attribution">
                <a href={us.user&&us.user.links&&us.user.links.html+'?utm_source=AeroViews&amp;utm_medium=referral'} target="unsplash">{us.user.name}</a>
                &nbsp;on&nbsp;
                <a href="https://unsplash.com/?utm_source=AeroViews&amp;utm_medium=referral" target="unsplash">Unsplash</a>
            </div>
        );
    }

    return null;
}