import React from 'react';
import './Page.scss';

interface PageProps
{
    children:any;
}

export default function Page({children}:PageProps)
{
    return (
        <div className="page nav-trans-target">
            {children}
        </div>
    )
}