let hostAddress;

if(window.location.host.indexOf('localhost')===0){
    hostAddress='http://localhost:5040';
}else if(window.location.host.indexOf('192.')!==-1){
    hostAddress='http://192.168.77.109:5040';
}else{
    hostAddress='https://api.grbll.opencanvas.services';
}

if(window.location.host.endsWith('.ngrok.io')){
    hostAddress='net-share-api.ngrok.io';
}



export default {
    hostAddress:hostAddress,
    apiBaseUrl:`${hostAddress}/api/`,
    webSocketBaseUrl:`ws${hostAddress.substring(4)}/ws/`
}